import React from 'react'
import styled from 'styled-components'

import Layout from 'src/components/Layout'
import FlexboxRow from 'src/components/FlexboxRow'
import GradientBG from 'src/components/GradientBG'
import HorizontalLine from 'src/components/HorizontalLine'
import StefanoPortrait from 'src/assets/img/StefanoPortrait.svg'
import ZachPortrait from 'src/assets/img/ZachPortrait.svg'


const Intro = styled.div.attrs({
  className: 'AboutIntro'
})`
  position: relative;
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(to right bottom, rgba(15, 94, 245, 1), rgba(6, 48, 232, 1));
  padding-top: 37vh;
  padding-left: 9vw;
  overflow: auto;

  h1 {
    font-size: 4.375rem;
    color: #fff;
    max-width: 60vw;
    font-weight: 900;
    margin-bottom: 1.6rem;
    text-transform: uppercase;
  }

  h2 {
    font-size: 1.875rem;
    max-width: 60vw;
    color: #fff;
  }
`

const StyledHeader = styled(FlexboxRow).attrs({
  className: 'HeaderWithLines'
})`
  color: var(--grey-blue);
  text-transform: uppercase;
  align-items: center;
  margin-bottom: 1rem;

  .HorizontalLine {
    border: 1px solid var(--deep-blue);
  }

  h3 {
    color: var(--deep-blue);
    font-size: 3rem;
    font-weight: 900;
    margin-right: 1rem;
  }
`

function Header({ children }) {
  return (
    <StyledHeader>
      <HorizontalLine />
        <h3>
          {children}
        </h3>
      <HorizontalLine />
    </StyledHeader>
  )
}

const Wrapper = styled.div.attrs({
  className: 'AboutWrapper'
})`
  min-height: 60vh;
  max-width: 75vw;
  padding-left: 8vw;
  padding-top: 21.5vh;

  h4, p {
    padding-left: 3.8vw;
  }

  h4 {
    font-size: 1.5rem;
  }

  p {
    font-weight: 300;
    font-size: 1rem;
  }
`

function AboutUs() {
  return (
    <Wrapper>
      <Header>About Us</Header>
      <h4>
        Training an AI model is often the easiest step.
      </h4>
      <p>
        Build the dataset.
        Prep the pipeline.
        Model the problem-space.
        Package the AI.
        Build a high-performance API.
        Share the model in a world-class user interface.
      </p>
      <p>
        Building AI in the real world requires skills that span from the Deep Learning practitioner to the full-stack web developer. We take AI models from architecture to pixels on the screen.
      </p>
    </Wrapper>
  )
}

const BioBlock = styled(FlexboxRow).attrs({
  className: 'BioBlock'
})`
  align-items: center;
  padding-top: 5vh;

  padding-left: 10vw;
  img {
    width: 200px;
    margin-right: 2vw;
  }

  p {
    padding-left: 0;
  }

  h2 {
    color: var(--deep-blue);
    font-weight: 900;
    font-size: 2.5rem;
    text-transform: uppercase;
    line-height: 2.5rem;
  }

  :last-of-type {
    padding-bottom: 15vh;
  }

`

function WhoWeAre() {
  return (
    <Wrapper>
      <Header>Who We Are</Header>
      <BioBlock>
        <img src={StefanoPortrait} />
        <div>
          <h2>Stefano <br/>Giomo</h2>
          <p>
            Stefano is a Deep Learning practitioner and computer vision expert with over 15 years of experience. His software and AI models power automation solutions for dozens of firms around the world.
          </p>
          <p>
            Stefano is a sought-after technical trainer, where he instructs software teams in architecture and applied Deep Learning.
          </p>
          <p>
            He is co-creator of Narcyso, a smart mirror and glasses try-on system that pioneered the application of computer vision to fashion in the 2000's. He is the recipient or co-recipient of 3 patents.
          </p>
        </div>
      </BioBlock>

      <BioBlock>
        <img src={ZachPortrait} />
        <div>
          <h2>Zachary <br/>Caceres</h2>
          <p>
            Zach is Deep Learning practitioner and web development expert focused on React, GraphQL, and serverless computing.
          </p>
          <p>
            As a consultant or early team member, Zach has built multiple web applications for startups and other clients. Zach is an active open-source contributor and maintains the most popular implementation of Google Brain's SpecAugment audio data augmentation in Pytorch. His <a href="https://zach.dev" rel="noopener noreferrer" target="_blank">personal website</a> has been featured in multiple outlets focused on web design.
          </p>
          <p>
            Originally trained as an economist, Zach is the winner of multiple national and international awards for economic research. He has been interviewed, published, or featured in <em>Virgin Entrepreneur</em>, <em>Vice Magazine</em>, <em>Reason Magazine</em>, <em>Foreign Policy</em>, <em>Smash'd</em>, <em>The Atlantic's CityLab</em> and has research in two academic volumes published by Palgrave MacMillan.
          </p>
        </div>

      </BioBlock>
    </Wrapper>
  )
}

export default ({
  history,
  location,
  match
}) => {
  return (
    <Layout
      history={history}
      location={location}
      match={match}
    >
      <Intro>
        <h1>We're engineers, <br />not academics.</h1>
        <h2>At Automation.co, we take cutting-edge Deep Learning models
          from <strong>architecture</strong> to <strong>user interface</strong>.</h2>
      </Intro>

      <GradientBG>
        <AboutUs />

        <WhoWeAre />

      </GradientBG>

    </Layout>
  )
}
